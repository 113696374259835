.InvitePatientView {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  background: rgba(0, 0, 0, 0.5);
  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow-y: scroll;
}

.InvitePatientView * {
  -webkit-user-select: none;
}

.InvitePatientView .text-style {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  color: var(--gray-color-new);
}

.InvitePatientView .action-button {
  width: 120px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InvitePatientView > .uploaded {
  box-sizing: border-box;
  padding: 30px;
  height: 543px;
  justify-content: space-around;
}

.InvitePatientView > .details-box {
  position: relative;
  width: 830px;
  background: white;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-y: auto;
}

.InvitePatientView > .details-box > .cross-button {
  position: absolute;
  top: 20px;
  right: 20px;

  width: 20px;
  height: 20px;

  filter: invert(96%) sepia(1%) saturate(4796%) hue-rotate(184deg)
    brightness(102%) contrast(71%);
  cursor: pointer;
}

.InvitePatientView > .details-box > .heading-label {
  margin: 30px 0px;
  width: 100%;
  height: 30px;

  font-size: 30px;
  line-height: 30px;
  /* identical to box height, or 100% */
}

.InvitePatientView > .details-box > .fields-div {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  /* background-color: red; */
  padding: 10px 30px;
  box-sizing: border-box;
  /* flex: 1; */
}

.InvitePatientView > .details-box > .invite-family-div {
  margin-left: 29px;
}

.InvitePatientView > .details-box > .invite-family-div > .invite-label {
  margin-bottom: 23px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  float: left;
  color: #2680bc;

  cursor: pointer;
  text-decoration: underline;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.InvitePatientView > .details-box > .buttons-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 45px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.InvitePatientView .buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.InvitePatientView .buttons-container > .button-style,
.InvitePatientView .clear-button {
  background: #ffffff;
  border: 1px solid #0b598d;
  box-sizing: border-box;
  color: #2680bc;
  margin: 6px 0;
  width: auto;
  min-width: 120px;
  padding: 0 10px;
}

.InvitePatientView #clear-button {
  margin: 0 !important;
}

.InvitePatientView .buttons-container > .button-style:hover {
  color: #ffffff;
  background: #2680bc;
}

.InvitePatientView > .details-box > .buttons-div > .send-button {
  background: #2680bc;
  color: white;
  margin-left: 10px;
}

.modification-div {
  display: flex;
  align-self: center;
  justify-self: center;
  /* background-color: red; */
  width: 100px !important;
  margin-top: 12px;
}

.modification-div > div {
  display: flex;
}

.modification-div .modification-button {
  width: 20px;
  height: 20px;

  border: 2px solid #ced4da;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  margin: 5px;
}

.InvitePatientView .css-1okebmr-indicatorSeparator {
  height: 17px;
  margin-right: 6px;
  align-self: center;
}

.animate-popup-appear {
  animation: animate-appear 0.3s ease-in-out;
}

.drop-down .DatePicker {
  position: relative;
  background-color: hsl(0, 0%, 100%);
  border: hsl(0, 0%, 80%) 1px solid;
  border-radius: 4px;
  height: 35px;
  outline: 0 !important;
  box-sizing: border-box;
  width: 100%;
  float: right;
}

.drop-down .DatePicker:hover {
  border-color: #80bdff !important;
}

.drop-down .DatePicker .react-datepicker-wrapper {
  position: absolute;
  border: none;
  font-size: 13px !important;
  color: rgba(73, 80, 87, 0.8) !important;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: transparent;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.drop-down .DatePicker .css-tlfecz-indicatorContainer {
  float: right;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__input-container {
  width: 100%;
}

.drop-down .DatePicker input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding-left: 15px;
}

.react-datepicker__input-container {
  height: 100%;
}

.InvitePatientView > .details-box .create-content-loading .loading {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  background-color: rgba(255, 255, 255, 0.384) !important;
}

.InvitePatientView > .details-box .create-content-loading .loading img {
  margin-top: 0% !important;
}

.select-wrap-media {
  width: 100%;
}

.InvitePatientView > .details-box > .buttons-div > .send-button:disabled {
  background: #2680bcc5;
}

.select-wrap-media input {
  color: rgba(73, 80, 87, 0.8) !important;
}

.css-2b097c-container div.css-yk16xz-control {
  border-color: none;
}

.alert-primary {
  position: absolute;
  bottom: 100px;
}

.null-field,
.null-field input {
  pointer-events: none;
  opacity: 0;
}

.custom-phone-input {
  height: 35px;
  font-size: 14px;
  border: 1px solid #ced4da;
  width: 100%;
  border-radius: 5px;
  padding: 0 10px;
}

@media only screen and (max-width: 750px) {
  .InvitePatientView {
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
  }

  .InvitePatientView > .details-box {
    margin: 30px 15px;
    max-height: none;
  }

  .InvitePatientView .fields-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    box-sizing: border-box;
  }

  .InvitePatientView .fields-div .FieldView {
    width: 100%;
  }
}

@keyframes animate-appear {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
