.PasswordResetPage .root-error {
  font-size: 18px;
  padding: 18px;
  text-align: center;
}

.PasswordResetPage .error-label {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #ff5733;
}

/* .PasswordResetPage .loading img {
  width: 73px;
  max-width: none;
  z-index: 0;
} */

.PasswordResetPage #recaptcha-container {
  margin-bottom: 5px;
}

/* .PasswordResetPage .login-content {
  z-index: 3;
} */

.PasswordResetPage .resend-box img {
  align-self: normal;
  margin-bottom: 2px;
}
