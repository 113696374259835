.ModalPopup {
  position: absolute;
  z-index: 111;
  /* z-index: 1111; */
}
.modal-backdrop.show {
  opacity: 0.5;
  z-index: 110;
}
.ModalPopup .modal-dialog {
  max-width: 1070px;
}
.ModalPopup .modal-content {
  padding: 0;
  overflow: hidden;
  border-radius: 15px;
}
.ModalPopup .modal-body span {
  position: absolute;
  bottom: 120px;
  left: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  padding: 10px 25px;
  color: #fff;
}
.ModalPopup .modal-header {
  background: #0063eb;
  text-align: center;
  padding: 16px 25px;
  border-radius: 12px 12px 0 0;
  margin: 0;
}
.ModalPopup .modal-header button {
  color: #fff;
  opacity: 1;
  font-size: 2.5rem;
}
.ModalPopup .modal-title {
  font-size: 16px;
  color: #fff;
  width: 100%;
}
.ModalPopup .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}
.ModalPopup .modal-flex-container {
  display: flex;
  align-items: center;
}
.ModalPopup .flex-container1 {
  width: 35%;
  text-align: left;
}
.ModalPopup .flex-container2 {
  width: 30%;
  text-align: center;
}
.ModalPopup .loading {
  background: transparent url("/assets/gif/animation_500_kc4ogugy.gif") center
    no-repeat;
  background-size: 100px 100px;
}
