.FieldView {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 45%;
}

.FieldView > .drop-down {
  position: relative;
}

.FieldView > .title-label {
  min-height: 19px;
  left: 30px;
  top: 238px;
  margin-bottom: 6px;
}

.FieldView .input-field {
  height: 35px;
  left: 30px;

  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 5px;

  outline: none;
  padding: 0 10px;

  width: 100%;
  -webkit-user-select: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.FieldView .input-field::placeholder {
  font-size: 13px;
  font-weight: 500;
  opacity: 1; /*Firefox*/
  height: 35px;
  line-height: 35px;
  color: rgba(73, 80, 87, 0.8);
}

.FieldView > .drop-down > select {
  appearance: none;
  background: none;
  outline: none;
}

.FieldView > .drop-down > .down-arrow {
  position: absolute;
  top: 0;
  right: 10px;
  width: 30px;
  height: 100%;
  padding: 10px 7px;
  /* background-image: url("../../../public/assets/images/icon-close.imageset/icon-close@2x.png"); */
}

.FieldView .fieldview-label {
  font-size: 12px;
  font-weight: 500;
  min-height: 8px;
  max-height: 8px;
  margin-top: 2px;
  margin-left: 1px;
  color: #ff5733;
}
