.popup {
  animation: popup 0.7s;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.main-popup-div {
  background: #f4f4f4;
  border-radius: 16px;
  width: 861px;
  height: 665px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.div-containing-label {
  padding-top: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  padding-bottom: 15px;
  position: relative;
}
.cross-button {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: -50px;
  box-shadow: none;
  outline: none;
  border: none;
  font-size: 24px;
  background: none;
}
.button-custom {
  background: #043293;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 132% */
  box-shadow: none;
  outline: none;
  text-align: center;

  color: #ffffff;
  height: 50px;
  width: 276px;
}
.div-containing-button {
  padding-bottom: 30px;
}
.gif-image {
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain !important;
}
.div-containing-img {
  text-align: center;
  padding: 0px;
  /* height: 600px; */
  width: 70% !important;
}
.button-custom-try {
  padding-top: 20px;
  text-align: center;
  color: #ff567a;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  /* text-decoration: underline; */
}
@media only screen and (max-width: 700px) {
  .main-popup-div {
    width: 100% !important;
    height: 100% !important;
  }
  .div-containing-img {
    width: 100% !important;
  }
}
